import Vue from 'vue';
import { BASEURL } from "@/config/index";
import MasterService from "@/services/MasterService";
import { authenticationService } from "@/services/AuthenticationService";

let userDetailsArray = {
  "cinema_id": null,
  "email_id": null,
  "first_name": null,
  "last_name": null,
  "middle_name": null,
  "mobile_no": null,
  "role_id": null,
  "user_id": null,
  "username": null,
  "role_name": null,
  "distributor_id": null,
  "cine_name": null
};

const data = function () {
  return {
    AdminError: "Oops! Something went wrong. kindly contact your administrator",
    GridViewFlag: false,
    NoRecords: "NoRecords",
    BASE_URL: BASEURL,
    data_loading: false,
    usersArray: Object.keys(userDetailsArray),
    UsersDetails: {
      ...userDetailsArray,
    },
    OrganizationGlobalOptions: [],
    secureStore: {
      set: authenticationService.set,
      get: authenticationService.get,
      remove: function (key) {
        window.localStorage.removeItem(key);
      }

    },
  }
}

const computed = {
  _getCountryList() {
    return (key, Id) => {
      console.log('key, Id :', key, Id);
      return []
    }
  },
  GetRoleId() {
    const {
      role_id
    } = authenticationService.get("USINFO");
    // this.login_user_role_id = role_id;
    return role_id
  }
};

const getFirstUserData = async (user_id) => {
  const {
    data
  } = await MasterService.GetUserInfoById({
    user_id
  });
  return data
}
const methods = {
  _CatchError: function (error) {
    Vue.toasted.show(error || "Oops! Something went wrong. kindly contact your administrator");
  },
  showLoading: function () {
    console.log("show_loading", this.data_loading);
  },
  hideLoading: function () {
    console.log("remove_loading", this.data_loading);

  },
  _GoToSomethingWentWrong: function () {
    this.$router.push({
      path: "/something-went-wrong"
    });
  },
  _GoToAccessDenied: function () {
    this.$router.push({
      path: "/access-denied"
    });
  },
  _GoToError404: function () {
    this.$router.push({
      path: "/404"
    });
  },
  _GoToRoute: function (path) {
    console.log('path :', path);
    this.$router.push({
      path: path,
    })
  },
  _GetGlobalOptions: function () {
    return new Promise((resolve, reject) => {
      MasterService.GetGlobalOptions()
        .then((response) => {
          const {
            data
          } = response;
          if (data && data.status && data.Records.length > 0) {
            this.secureStore.set('GLOP', data.Records);
            //
            data.Records.map((x) => {
              if (x.go_key === 'CURRENCY') {
                // BRIJ GLOBAL OPTIONS CURRENCY
                this.secureStore.set('BRGLOPCUR', x.go_value);
                this.secureStore.set('BRGLOPCU', x);
              } else if (x.go_key === "APPLICATION_LANGUAGE") {
                // BRIJ GLOBAL OPTIONS APPLICATION_LANGUAGE
                this.secureStore.set('BRGLOPAL', x.go_value);
              } else if (x.go_key === 'OPERATION_COUNTRY') {
                // BRIJ GLOBAL OPTIONS OPERATION COUNTRY
                this.secureStore.set('BRGLOPCO', x.go_value);
              } else if (x.go_key === 'STOCK_DIRECT_TRANSFER') {
                // BRIJ GLOBAL OPTIONS STOCK DIRECT TRANSFER
                this.secureStore.set('BRGLOPSTDITR', x.go_value);
              } else if (x.go_key === 'STOCK_DEDUCTION') {
                // BRIJ GLOBAL OPTIONS STOCK DEDUCTION
                this.secureStore.set('BRGLOPSTDE', x.go_value);
              } else if (x.go_key === 'STOCK_DEDUCTION_CONCESSION') {
                // BRIJ GLOBAL OPTIONS STOCK DEDUCTION CONCESSION
                this.secureStore.set('BRGLOPSTDECO', x.go_value);
              } else if (x.go_key === 'ENABLE_FNB') {
                this.secureStore.set('ENABLE_FNB', x.go_value);
              }
            });
            resolve('data.Records')
          } else {
            // Show Error
            // console.log('data.message :', data.message);
            reject(data.message)
          }
        }).catch((error) => {
          //Catch Error
          // console.log('catch error on GetGlobalOptions :', error);
          reject('catch error on GetGlobalOptions')

        });
    })

  },
  _GetViewUsersDetails: function (user_id) {
    let string = ''
    let promise = getFirstUserData(user_id).then((res) => {
      string = res.status && res['Records'] && res.Records.length > 0 ? res.Records[0].user_name : '-'
    });
    return promise
  },
  CreateFilterObject: function (array) {
    let obj = {};
    array.map((x) => {
      obj[x.key] = '';
    })
    return obj;
  },
  GetMonthFirstDate: function () {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, "01"].join("-");
  },
  GetCurrentDate: function () {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  formatDate: function (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  },
  _formatDate_dd_mm_yyyy: function (date) {
    var d = new Date(date);
    let formatted_date = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
    return formatted_date;
  },
  getRequestBody: function (method = "GET", array = [], Methods = {}) {
    let payload = {};
    (async function () {
      const promises = array.map(x => {
        console.log('x :', x);
        payload[x.fieldname] = x.fieldvalue;
      });
      await Promise.all(promises);
      // payload['table'] = Methods["tableName"]
    })()
    if (method == 'POST') {

      return {
        url: Methods[method] + Methods["tableName"], // + Methods["urlKey"],
        method,
        payload
      };
    } else if (method == 'PUT') {
      let url = null;
      console.log('Method :', Methods['params']);
      if (Methods['params']) {
        url = Methods[method] + Methods["tableName"] + `/${Methods["params"]['id']}`;
      } else {
        url = Methods[method] + Methods["tableName"];
      }
      console.log('url :', url);

      return {
        url,
        method: "PUT",
        payload
      };

    } else if (method == 'GET') {
      let url = null;
      if (Methods['params']) {
        url = Methods[method] + Methods["tableName"] + `/${Methods["params"]['id']}`;
      } else {
        url = Methods[method] + Methods["tableName"];
      }

      return {
        url,
        method: "GET",
        payload: {}
      };

    } else {
      return {
        url: '',
        method: "GET",
        payload: {}
      };
    }

  },
  currFormat(val) {

    console.log({ val });

    if (!val || isNaN(val)) {
      return val
    }

    if (!val.toString().includes('.')) {
      return val
    }

    return Number(val).toFixed(2)

  },
}

const storeService = {
  data: () => data(),
  computed,
  methods
}

export default storeService
